export const routes = [
    {
        path: '/sales_persons',
        name: 'sales_persons.browse',
        component: () => import(/* webpackChunkName: "SalesPersonsBrowse" */ '@/views/app/SalesPersonsBrowse.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/sales_persons/:id/clone',
        name: 'sales_persons.clone',
        component: () => import(/* webpackChunkName: "SalesPersonsActions" */ '@/views/app/SalesPersonsActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/sales_persons/create',
        name: 'sales_persons.create',
        component: () => import(/* webpackChunkName: "SalesPersonsActions" */ '@/views/app/SalesPersonsActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/sales_persons/:id/edit',
        name: 'sales_persons.edit',
        component: () => import(/* webpackChunkName: "SalesPersonsActions" */ '@/views/app/SalesPersonsActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/sales_persons/:id/delete',
        name: 'sales_persons.delete',
        component: () => import(/* webpackChunkName: "SalesPersonsActions" */ '@/views/app/SalesPersonsActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup" }
    },
    {
        path: '/sales_persons/:id/show',
        name: 'sales_persons.show',
        component: () => import(/* webpackChunkName: "SalesPersonsActions" */ '@/views/app/SalesPersonsActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
]